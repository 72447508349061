// If you want to override variables do it here
@import "variables";

// Import styles with default layout.
// If you are going to use dark layout please comment next line
@import "~@coreui/coreui-pro/scss/coreui.scss";

// Import styles with dark layout
// If you want to use dark layout uncomment next line
//@import "~@coreui/coreui-pro/scss/themes/dark/coreui-dark.scss";

// Temp fix for reactstrap
@import "~@coreui/coreui-pro/scss/_dropdown-menu-right.scss";

// If you want to add something do it here
@import "custom";

// ie fixes
@import "ie-fix";

// Spinkit
$spinkit-spinner-color: $body-color;
@import "~spinkit/scss/spinkit.scss";

.white-card {
  background-color: white !important;
  border-radius: 15px;
}
.card-border {
  border-style: solid;
  border-radius: 15px;
  border-color: white;
}
.card-text {
  color: grey;
  padding: 5px 0px;
}
.card-number {
  font-size: 35px;
  font-weight: bold;
  color: black;
}
.card-content {
  padding-left: 6px;
}
.card-image {
  height: 56px;
  width: 56px;
  border-radius: 28px;
  margin-bottom: 20px;
}
.case-text {
  font-size: 15px;
}
.reported-by-text {
  margin-right: 66px;
}
// .th {
// 	width: 100px;
// 	background-color: aqua;
// }

// .searchInput {
// 	width: 88%;
// 	border: none;
// 	padding: 20px 10px;
// 	outline: none;
// 	height: 30px;
// 	margin-right: 10px;
// 	border-radius: 20px;
// }
.filter {
  min-width: 20%;
  padding: 10px 5px;
  margin-right: 20px;
  background-color: white;
  font-size: 15px;
  color: grey;
  border-radius: 20px;
  // .Dropdown-menu {
  // 	::-webkit-scrollbar {
  // 		width: 1px;
  // 	}
  // 	outline: none;
  // }
}
.filterIcon {
  margin-left: 13px;
  margin-top: 2px;
  color: grey;
}
.sort-by {
  display: flex;
  margin-bottom: 10px;
  padding: 10px 0px;
  padding-right: 6px;
  margin-left: -30px;
  background-color: white;
  font-size: 15px;
  color: grey;
  border-radius: 20px;
  .Dropdown-control {
    outline: none;
  }
}
.sort-btn {
  margin-left: -5px;
  margin-right: 5px;
}
.date-range{
   width: 80%;
  margin-bottom: 10px;
  padding: 10px 5px;
  margin-left: 0px;
  background-color: white;
  font-size: 15px;
  color: grey;
  border-radius: 20px;
  .Dropdown-control {
    outline: none;
  }
}
.sort-by-user {
  width: 80%;
  margin-bottom: 10px;
  padding: 10px 5px;
  // margin-right: 20px;
  background-color: white;
  font-size: 15px;
  color: grey;
  border-radius: 20px;
  .Dropdown-control {
    outline: none;
  }
}
.sortIcon {
  margin-left: 5px;
  margin-top: 2px;
  color: grey;
  width: 15px;
}
.wrapper {
  margin-left: 0px;
}
// Media Queries for Export Alarms Button for different screen width
@media (min-width: 768px) {
  .export-button {
    width: 102%;
  }
}
/* For screens below 768px */
@media (max-width: 767px) {
  .export-button {
    width: 70%;
  }
}
@media (max-width: 461px) {
  .export-button {
    width: 100%;
  }
}

@media only screen and (max-width: 1200px) {
  .searchInput {
    margin-right: 0px;
  }
  .filterIcon {
    margin-left: 3px;
  }
  .sortIcon {
    margin-left: 3px;
  }
}
@media only screen and (max-width: 1050px) {
  .case-text {
    font-size: 13px;
  }
}
@media only screen and (max-width: 950px) {
  .case-text {
    font-size: 15px;
  }
}
@media only screen and (max-width: 1200px) {
  // .sort-by {
  //   width: 90px;
  // }
  .sort-by-user {
    width: 90px;
  }
  .date-range{
    width: 80%;
  }
}

.sort {
  width: 22%;
  padding: 10px 5px;
  background-color: white;
  font-size: 15px;
  color: grey;
  border-radius: 20px;
}
.filterText {
  width: 65%;
  padding-left: 5px;
}
.sortText {
  width: 65%;
  padding-left: 5px;
}

.statusFilter {
  background-color: blue;
}
.FilterSection {
  margin-top: 10px;
}
.FilterSectionRow {
  // background-color: gray;
}
.status {
  // width: 50%;
  padding: 10px 10px;
  background-color: white;
  font-size: 15px;
  color: grey;
  border-radius: 20px;
  margin-right: 20px;
  .fa-times {
    margin-left: 4px;
  }
}
.location {
  // width: 50%;
  padding: 10px 10px;
  background-color: white;
  font-size: 15px;
  color: grey;
  border-radius: 20px;
}
.applyButton {
  margin: 10px 72px;
}
.filterDropdown {
  position: absolute;
  z-index: 1;
  background-color: white;
  top: 45px;
  width: 220px;
  box-shadow: 5px 10px 12px #888888;
  border: 1px solid;
  border-radius: 10px;

  // border: solid;
  border-color: #adb3b2;
}
.filterDropdown-user {
  position: absolute;
  z-index: 1;
  background-color: white;
  top: 45px;
  width: 220px;
  box-shadow: 5px 10px 12px #888888;
  border: 1px solid;
  border-radius: 10px;

  // border: solid;
  border-color: #adb3b2;
}
.dateDropdown {
  position: absolute;
  z-index: 1;
  background-color: white;
  top: 45px;
  // width: 220px;
  box-shadow: 5px 10px 12px #888888;
  border: 1px solid;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  // border: solid;
  border-color: #adb3b2;
}
.Dropdown-placeholder {
  width: 170%;
}
.textStyle {
  color: rgb(143, 143, 143);
}
.card-select {
  height: 30px;
  background-color: white;
  border: none;
  width: 95%;
  font-size: 18px;
}
.MainTextStyle {
  font-size: 16px;
  color: black;
  padding: 0px 0px;
  margin: 8px 0px 10px 0px;
  font-weight: bold;
}
.seperator {
  background-color: rgba(210, 215, 211, 0.3);
  height: 1px;
  width: 100%;
  margin: 10px auto;
}
.cardHeading {
  font-size: 20px;
  margin: 5px 20px;
}
.cardBody {
  font-size: 14px;
  flex: none;
}
.details-card {
  min-height: 750px;
}
.resource-role {
  margin-left: 58px;
}
.ekg-image {
  width: 78px;
  height: 78px;
  border-radius: 10px;
  cursor: pointer;
}
@media only screen and (max-width: 1130px) and (min-width: 990px) {
  .ekg-image {
    width: 60px;
    height: 60px;
    border-radius: 10px;
    cursor: pointer;
  }
}
.chat-cards {
  height: 50px;
  border: 1px solid black;
  margin: 10px 0px;
  padding: 0px;
  width: 100%;
}
.chat-cards-icon {
  height: 35px;
  width: 35px;
}
.image-container {
  height: 35px;
  width: 35px;
  margin: 5px 0px;
  background-color: rgb(237, 242, 244);
  border-radius: 17.5px;
  padding: 1px;
}
.chat-cards-text {
  padding: 0px;
  margin: auto;
}
.chat-cards-arrow {
  padding: 12px 5px;
  // margin: auto;
}
.alarm-detail-header {
  margin: 10px 0px;
  align-items: center;

  .back-icon {
    cursor: pointer;
    font-size: 25px;
    font-weight: bold;
    margin-top: -5px;
    .icons {
      margin-right: 10px;
      margin-left: -12px;
    }
  }
  .header-time {
    height: 35px;
    width: 150px;
    // background-color: white;
    // padding: 10px 20px;
    color: red;
    font-size: 15px;
    align-items: center;
    // justify-content: center;
  }
  .sidebar .nav-link.active {
    color: #fff;
    background: red;
  }
  .resources-style {
    display: flex;
  }
  .resources-style {
    display: flex;
    width: 100px;
    height: 100px;
    background-color: red;
  }
  .sidebar {
    display: flex;
    flex-direction: column;
    padding: 0;
    color: #fff;
    background: #2f353a;
  }
  // .sidebar .nav-link.active {
  //   background: red;
  // }
  .sidebar .nav-link {
    display: block;
    padding: 0.75rem 1rem;
    color: #fff;
    text-decoration: none;
    background: transparent;
  }
  .btn-square {
    font-weight: bold;
    font-size: 15px;
    margin-bottom: 5px;
  }
  .buttons {
    margin-top: 10px;
    margin-left: 5px;
  }
}
.status-modal {
  float: right;
  width: 300px;
  margin-top: 0px;
  .status-modal-header {
    height: 50px;
    .close-icon {
      position: absolute;
      right: 10px;
    }
    .status-heading {
      background-color: "red";
      margin-left: 10px;
      align-items: center;
    }
  }
}
.button-container {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  align-items: center;
  justify-content: center;
}
.modal-body-schedule {
  position: relative;
  flex: 1 1 auto;
  padding-top: 2rem;
  padding-bottom: 1rem;
  padding-left: 4rem;
  padding-right: 4rem;
}
@media only screen and (max-width: 500px) {
  .modal-body-schedule {
    position: relative;
    flex: 1 1 auto;
    padding: 0.6rem;
  }
}

.schedule-modal {
  .modal-buttons {
    margin-top: 57px;
    @media only screen and (max-width: 600px) {
      .modal-buttons {
        margin-top: 20px;
      }
    }

    .btn-square {
      margin: 10px 0px;
    }
  }
  .notes {
    border-bottom: 1px solid grey;
    margin-bottom: 5px;

    width: 94%;
    margin: auto;
    .notes-input {
      outline: none;
      border: none;
    }
    .from-to {
      margin-top: 20px;
    }
  }
  .end-wrapper {
    margin-top: 25px;
    .end {
      height: 32px;
      border-bottom: 1px solid grey;
      width: 90%;
      margin-left: 0px;
      margin-bottom: 10px;
      .end-select {
        border: none;
        background-color: white;
        outline: none;
        width: 100%;
        font-size: 20px;
        margin-left: 0px;
      }
    }
    .date-time {
      width: 100%;
      .date-text {
        font-size: 18px;
        color: black;
        margin-bottom: 2px;
        width: 80%;
      }
      .date-calendar {
        margin-top: 5px;
        margin-left: 0px;
        color: grey;
        cursor: pointer;
      }
    }
  }
  .repeat-from-to {
    margin-top: 25px;
    font-size: 16px;
    font-weight: bold;
  }
  .repeat-on {
    .weekdays {
      height: 30px;
      width: 30px;
      border-radius: 30px;
      background-color: #fafafa;
      margin: 10px 15px;
      text-align: center;
      color: black;
      font-size: 20px;
      box-shadow: 3px 3px 3px #888888;
    }
    .weekdays-selected {
      height: 30px;
      width: 30px;
      border-radius: 30px;
      background-color: #d55091;
      margin: 10px 15px;
      text-align: center;
      color: white;
      font-size: 20px;
      box-shadow: 3px 3px 3px #888888;
    }
    .monthdays {
      height: 30px;
      width: 30px;
      border-radius: 30px;
      background-color: #fafafa;
      margin: 10px 15px;
      text-align: center;
      color: black;
      font-size: 20px;
      box-shadow: 3px 3px 3px #888888;
    }
    .monthdays-selected {
      height: 30px;
      width: 30px;
      border-radius: 30px;
      background-color: #d55091;
      margin: 10px 15px;
      text-align: center;
      color: white;
      font-size: 20px;
      box-shadow: 3px 3px 3px #888888;
    }
  }
  .repeats {
    height: 32px;
    border-bottom: 1px solid grey;
    width: 88%;
    margin-left: 0px;
    .value {
      font-size: 18px;
      margin-left: 5px;
      width: 80%;
    }
    .caret-up {
      margin-right: 20px;
    }
  }
  .date-time {
    height: 32px;
    border-bottom: 1px solid grey;
    width: 84%;
    margin-left: 0px;
    margin-bottom: 10px;

    .date-select {
      outline: none;
      background-color: white;
      border: none;
      width: 96%;
      font-size: 18px;
      .-ms-expand {
        color: grey;
      }
    }
    .text {
      font-size: 18px;
      color: black;
      // margin-right: 54px;
      margin-bottom: 2px;
      width: 86%;
    }
    .calendar {
      margin-top: 2px;
      color: grey;
      margin-left: 10px;
      cursor: pointer;
    }
    .from-calendar {
      background-color: coral;
      position: absolute;
      left: 50px;
      top: 55px;
      z-index: 1;
    }
    .to-calendar {
      background-color: coral;
      position: absolute;
      left: 100px;
      top: 55px;
      z-index: 1;
    }
    .clock {
      color: grey;
      margin-top: 3px;
      cursor: pointer;
    }
  }
  .from-to {
    margin-bottom: 10px;
    color: grey;
  }
  .radios {
    margin-bottom: 20px;
  }
  .available {
    margin-left: 5px;
    margin-top: 30px;
    font-size: 18px;
    .radio {
      margin-top: 3px;
      height: 20px;
      width: 20px;
      margin-right: 10px;
    }
  }
  margin-top: 120px;
}
.fa-check-circle {
  padding: 0px;
  margin: 0px;
  position: absolute;
}
.status-check-line1 {
  height: 100px;
  width: 1px;
  margin-top: 65px;
  margin-left: 8px;
  // color: blue;
  background-color: #2faff5;
}
.status-check-line2 {
  height: 107px;
  width: 1px;
  margin-left: 8px;
  background-color: #2faff5;
}
.shock-details-row {
  padding: 5px 0px;
}
.reset-wrapper {
  padding: 0px;
}
.reset-button {
  border-radius: 20px;
  background-color: white;
  margin: 3px 5px;
  width: 102%;
  height: 34px;
  &:active:focus {
    outline-color: none;
  }
  border: 1px solid #ccc;
}
.export-button {
  border-radius: 30px;
  background-color: blue;
  margin: 3px 0px;
  margin-right: 15px;
  height: 35px;
  color: white;
  &:active:focus {
    outline-color: none;
  }
  border: 1px solid #ccc;
}
.three-options {
  margin: 10px 0px;
  width: 94%;
  .options-radio {
    height: 20px;
    width: 20px;
  }
}
.options-radio-label {
  margin-left: 5px;
}
.inner-label {
  padding: 2px 0px 8px 0px;
  font-size: 15px;
}
// .c1 {
// 	top: 60px;
// 	background-color: white;
// 	color: #2faff5;
// }
// .c2 {
// 	top: 160px;
// 	background-color: white;
// 	color: #2faff5;
// }
// .c3 {
// 	top: 260px;
// 	background-color: white;
// 	color: #2faff5;
// }
// .c4 {
// 	top: 360px;
// 	background-color: white;
// 	color: #2faff5;
// }
// .c5 {
// 	top: 460px;
// 	background-color: white;
// 	color: #2faff5;
// }
// .c6 {
// 	top: 560px;
// 	background-color: white;
// 	color: #2faff5;
// }
// .c7 {
// 	top: 660px;
// 	background-color: white;
// 	color: #2faff5;
// }
// .c8 {
// 	top: 760px;
// 	background-color: white;
// 	color: #2faff5;
// }
// .c9 {
// 	top: 860px;
// 	background-color: white;
// 	color: #2faff5;
// }
// .c10 {
// 	top: 960px;
// 	background-color: white;
// 	color: #2faff5;
// }
// .c11 {
// 	top: 1060px;
// 	background-color: white;
// 	color: #2faff5;
// }
// .c12 {
// 	top: 1160px;
// 	background-color: white;
// 	color: #2faff5;
// }
// .c13 {
// 	top: 1160px;
// 	background-color: white;
// 	color: #2faff5;
// }
// .c14 {
// 	top: 1160px;
// 	background-color: white;
// 	color: #2faff5;
// }
.c20 {
  position: absolute;
  background-color: white;
  color: #2faff5;
  right: 20px;
}
.status-modal {
  .status {
    border: 1px solid grey;
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 10px 0px;
    .status-seperator {
      background-color: grey;
      height: 1px;
      width: 100%;
    }
    .status-date {
      height: 30px;
      padding: 5px;
      margin-left: 10px;
      .icons {
        margin-right: 10px;
      }
    }
    .status-text {
      font-size: 18px;
      font-weight: bold;

      padding: 8px;
      margin-left: 5px;
    }
    .icons {
      margin-right: 5px;
    }
  }
}
.ems-body-data {
  padding: 0px;
}
.ems-body-map {
  padding: 0px;
}
.ems-data {
  margin: 20px 0px;
  .ems-value {
    font-size: 17px;
    font-weight: bold;
  }
  .ems-label {
    color: grey;
  }
}
.ems-close-icon {
  position: absolute;
  background-color: pink;
  right: 10px;
  z-index: 1;
}
.schedule-header {
  margin: 20px 0px;
  .heading {
    font-size: 30px;
    font-weight: bold;
    margin-top: -5px;
  }
}
.user-status {
  outline: none;
  border: none;
  padding: 3px;
  background-color: white;
}
.react-autosuggest__input {
  // background-color: #d55091;
  width: 100%;
  border: none;
  padding: 20px 10px;
  outline: none;
  height: 30px;
  margin-right: 10px;
  border-radius: 20px;
}
.react-autosuggest__container {
  width: 90%;
}
.form-autosuggest {
  width: 84%;
  height: 42px;
  margin-right: 30px;
  margin-left: -2px;
  margin-bottom: 5px;
  padding-bottom: 1px;
  border-bottom: 1px solid grey;
  font-size: 17px;
}
.search-container {
  display: flex;
  justify-content: flex-start;
}
.locationFilters {
  display: flex;
  flex-wrap: wrap;
  
}
@media (min-width: 992px) {
  .locationFilters {
    padding: 0px;
  }
}
.search {
  width: 100%;
  display: flex;
  height: 40px;
  background-color: white;
  margin-right: 20px;
  margin-left: -2px;
  border-radius: 20px;
  margin-bottom: 5px;
}
.search-user {
  width: 100%;
  display: flex;
  height: 40px;
  background-color: white;
  margin-right: 30px;
  margin-left: -2px;
  border-radius: 20px;
}
.searchIcon {
  padding: 10px 0px;
  color: grey;
}
.select-location-text {
  color: black;
  font-size: 15px;
  padding: 2px 76px;
  padding-top: 10px;
}
.select-location {
  border: none;
  outline: none;
  height: 36px;
  width: 100%;
  background-color: white;
  border-bottom: 1px solid #acb3b1;
  padding: 2px 8px;
  color: grey;
  cursor: pointer;
}
.validation-message {
  margin: 5px 0px 10px 0px;
  color: red;
}
.location-sort {
  padding: 5px;
  margin-left: 3px;
  .icons {
    margin-left: 5px;
    height: 20px;
    width: 30px;
    cursor: pointer;
  }
  .sort-selected {
    .icons {
      color: blue;
    }
  }
}
.suggestion-container {
  background-color: white;
  z-index: 1;
  position: absolute;
  width: 90%;
  max-height: 300px;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: auto;
  box-shadow: 5px 10px 12px #888888;

  :first-child {
    border-top: 0.5px solid #d2d7d3;
  }
  // border: 1px solid grey;
  // border-left: 1.5px solid grey;
  .suggestion-item {
    border-right: 0.5px solid #d2d7d3;
    border-left: 0.5px solid #d2d7d3;
    cursor: pointer;
    padding: 3px 10px;
    font-size: 16px;
    // background-color: #d55091;
    &:hover {
      background-color: #f2f9fc;
    }
  }
}
.suggestion-container-form {
  background-color: white;
  z-index: 1;
  position: absolute;
  width: 80%;
  max-height: 300px;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: auto;
  box-shadow: 5px 10px 12px #888888;

  :first-child {
    border-top: 0.5px solid #d2d7d3;
  }
  // border: 1px solid grey;
  // border-left: 1.5px solid grey;
  .suggestion-item {
    // border-top: 0.5px solid grey;
    // border-bottom: 0.5px solid grey;
    border-right: 0.5px solid #d2d7d3;
    border-left: 0.5px solid #d2d7d3;
    padding: 3px 10px;
    font-size: 16px;
    &:focus {
      background-color: orange;
    }
  }
}
.clock-view {
  margin-top: 35px;
  margin-left: 100px;
  position: absolute;
  z-index: 1;
}
.schedule-form-error {
  color: red;
  margin-bottom: 10px;
}
.input-group {
  margin-bottom: 10px;
}
.table_body {
  cursor: pointer;
  // background-color: #d55091;
}
.error-message {
  padding: 10px 0px;
  color: red;
  font-size: 18px;
}
.nav {
  .nav-item {
    .nav-link {
      margin-top: 5px;
      background-color: #2493df;
      font-size: 15px;

      &:hover {
        background-color: #63c2de;
      }
      .drawer-icon {
        // padding: 0px 10px;
        height: 20px;
        width: 20px;
        margin-right: 10px;
        margin-bottom: 3px;
        object-fit: cover;
      }
    }
  }
}

.username {
  margin-right: 25px;
  font-size: 16px;
  font-weight: bold;
  // max-width: 140px;
}
.no-data-message {
  font-size: 20px;
  margin: 20px 0px;
}
.drop-down {
  padding: 0px;
}
.drop {
  border-radius: 20px;
  // margin-top: 5px;
  font-size: 14px;
  padding-right: 5px;
  padding-bottom: 8px;
  // margin-left: 10px;
  :first-child {
    // margin-left: 5px;
    height: 42px;
    // margin-right: 5px;
    // border-radius: 20px;
  }
  .Dropdown-arrow {
    height: 5px;
  }
}
.schedule-pic {
  transform: translateY(8px);
  border-radius: 25px;
  margin-right: 2px;
}
.logo {
  margin: auto;
}
.table-header {
  font-size: 20px;
  margin-bottom: 10px;
}
.year-picker {
  float: left;
  z-index: 1;
}
.filter-container {
  margin: auto;
}
@media only screen and (min-width: 752px) {
  .merge-cards {
    border: none;
    border-radius: 0px;
  }
}

.padding-5 {
  padding: 5px;
}
.padding-0 {
  padding: 0px;
}
.switch {
  padding: 0px;
}
.padding-2 {
  padding: 3px;
}
.custom-style {
  border-radius: 4px;
  border-width: 1px;
  border-color: #ccc;
}
.my-menu {
  background-color: #d55091;
  ::-webkit-scrollbar {
    width: 1px;
  }
}
.logout {
  margin-bottom: 20px;
}
.email-table {
  max-width: 200px;
}
.ds {
  margin: auto;
}
.logout-modal {
  margin-top: 250px;
}
.filter-container {
  width: 100%;
  padding: 0px;
}
.case-id-search {
  // width:20%;
  display: flex;
  background-color: white;
  padding: 0px 10px;
  border-radius: 20px;
}
.input-case-id {
  border-radius: 20px;
  outline: none;
  border: none;
  width: 88%;
}
// .MuiInput-underline {
// 	&:after {
// 	border: none;
// 	transition: none;
// 	}
// 	&:before {
// 		border: none;
// 		transition: none;
// 	}
// }
.MuiInput-underline:before {
  border-bottom: none !important;
  transition: none !important;
}
.MuiInput-underline:after {
  border-bottom: none !important;
  transition: none !important;
}
.MuiIconButton-root {
  transition: none;
  outline: none !important;
  border: none !important;
}
.MuiIconButton-root:hover {
  background-color: transparent !important;
}
.clock-wrapper {
  width: 100%;
  // margin-left: -15px;
  // background-color: yellow;
  margin-top: -20px;
  //  border-bottom: 1px solid grey;
  // background-color: #d55091;
  //  margin-top: 20px;
}
.clock-label {
  height: 20px;
  // margin-left: 18px;
  color: grey;
  font-size: 16px;
  // margin-bottom: 30px;
}
.MuiInputBase-input {
}
// @media only screen and (max-width: 600px) {
// 	.ekg-clicked-image {
// 		max-height:100px;
// 		max-width: 100px;
// 		height:100%;
// 		width:100%;
// 	}
// }
.ekg-clicked-image {
  // max-height: 500px;
  // max-width: 500px;
  // height:100%;
  // width:100%;
}
.ekg-modal {
  // 		height:900px;
  // margin-top: 60px;
  // margin-bottom: 60px;
}
.close-icon-ekg {
  cursor: pointer;
  position: absolute;
  background-color: rgba(131, 145, 146, 0.8);
  color: rgba(0, 0, 0, 0.8);
  margin: auto;
  margin: 5px 0px 0px 5px;
  right: 5px;
  padding: 0px 2px;
}
.close-icon-schedule {
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 100;
}
.Dropdown-root {
  position: relative;
}

.Dropdown-control {
  position: relative;
  overflow: hidden;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 20px;
  box-sizing: border-box;
  color: #333;
  cursor: default;
  outline: none;
  padding: 8px 52px 8px 10px;
  transition: all 200ms ease;
}
.resource-role {
  color: gray;
  margin-left: 55px;
  transform: translateY(-15px);
}
.Dropdown-control:hover {
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
}

.Dropdown-arrow {
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: " ";
  display: block;
  height: 0;
  margin-top: -ceil(2.5);
  position: absolute;
  right: 10px;
  top: 14px;
  width: 0;
}

.is-open .Dropdown-arrow {
  border-color: transparent transparent #999;
  border-width: 0 5px 5px;
}

.Dropdown-menu {
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
}

.Dropdown-menu .Dropdown-group > .Dropdown-title {
  padding: 8px 10px;
  color: rgba(51, 51, 51, 1);
  font-weight: bold;
  text-transform: capitalize;
}

.Dropdown-option {
  box-sizing: border-box;
  color: rgba(51, 51, 51, 0.8);
  cursor: pointer;
  display: block;
  padding: 8px 10px;
}

.Dropdown-option:last-child {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}

.Dropdown-option:hover {
  background-color: #929799;
  color: #333;
}

.Dropdown-option.is-selected {
  background-color: #f2f9fc;
  color: #333;
}

.Dropdown-noresults {
  box-sizing: border-box;
  color: #ccc;
  cursor: default;
  display: block;
  padding: 8px 10px;
}

.repeat-on-hint{
  margin-top:20px;
  color: gray;
}
.t-center{
  text-align: center
}